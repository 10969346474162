<template>
  <div>
    <v-row class="ma-0 pb-5 px-2">
      <v-col
          cols="6"
          md="10"
      >
        <h1>
          Liste des Differente Stock de Fourniture
        </h1>
      </v-col>
      <v-col
          cols="6"
          md="6"
      >
        <h4>
          Nombre de Stock: {{listStock.length}}
        </h4>
      </v-col>
    </v-row>
    <v-row
        class="match-height"
        style="margin-bottom: 2%;"
    >
      <v-col
          :key="stock.id"
          cols="12"
          v-for="stock in listStock"
          md="6"
      >
        <statistics-card-vertical
            color="success"
            :icon="icon"
            :statistics="stock.stock_amount+' '+stock.supply.sup_name +' Restant'"
            :stat-title="stock.stock_designation"
            :subtitle="'Stock de : '+stock.supply.sup_name"
        ></statistics-card-vertical>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import StatisticsCardVertical from '@/views/treasury/StatisticsCardVertical';
import { mdiBank } from '@mdi/js';

export default {
  name: 'Stocks',
  components: {
    StatisticsCardVertical,
    mdiBank,
  },
  data() {
    return {
      listStock: [],
      icon: mdiBank,
    }
  },
  mounted() {
    this.loadStock()
  },
  methods: {
    loadStock() {
      this.$axios.get(this.$endpoint.LoadStock).then(rp => {
        this.listStock = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
